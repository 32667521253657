<template>
  <div class="preparation-for-manual">
    <NavBack/>
    <div class="fixed-background sticky-header">
      <MenuAndLogo></MenuAndLogo>
    </div>
    <div class="has-padding">
      <div class="page-title has-text-weight-semibold has-text-centered">Add new bank</div>
      <div class="main has-background-white has-text-centered">
        <div class="has-text-weight-semibold box-title">Manually link your account</div>
        <div class="box-subtitle">To manually add a bank accout to your addy wallet, please ensure this account has the following attributes: </div>
        <div class="checks has-text-left">
          <div v-for="(check, index) in checks" :key="index">
            <img src="@assets/icons/check.svg" alt="check icon">
            <span class="is-uppercase has-text-weight-bold has-text-blue">{{check}}</span>
          </div>
        </div>
      </div>
      <BottomButtons
        :showSteps="false"
        :nextDisabled="false"
        @goNext="toBankAccountPage"
        @goBack="goBack"
      ></BottomButtons>
    </div>
  </div>
</template>
<script>
import NavBack from '@components/desktop/header-nav-back.vue'
import MenuAndLogo from '@views/index/MenuAndLogo.vue'
import BottomButtons from '@components/button/bottom-buttons.vue'

export default {
  data() {
    return {
      checks: ['chequing', 'held in your legal name', 'is a personal account'],
    }
  },
  components: {
    NavBack,
    MenuAndLogo,
    BottomButtons,
  },
  methods: {
    toBankAccountPage() {
      this.$router.push('/wallet/accounts/manual/bankAccount')
    },
    goBack() {
      this.$router.go(-1)
    },
  },
}
</script>

<style lang="scss" scoped>
@import '@assets/style/desktop-constants.scss';

.preparation-for-manual {
  position: absolute;
  top: 0;
  left: 0;
  width: 100vw;
  padding-bottom: 80px;
  min-height: 100vh;
  .has-padding {
    padding: 0 25px;
    .page-title {
      font-size: 32px;
      line-height: 32px;
      margin-bottom: 16px;
      letter-spacing: 0.03em;
    }
    .main {
      height: 426px;
      border-radius: 12px;
      box-shadow: 0px 20px 40px rgba(215, 145, 51, 0.15);
      margin-bottom: 27px;
      .box-title {
        font-size: 16px;
        padding-top: 24px;
      }
      .box-subtitle {
        padding: 8px 21px 84px;
        font-size: 16px;
        line-height: 19px;
      }
      .checks {
        font-size: 18px;
        display: inline-block;
        > div {
          margin-bottom: 28px;
          span {
            font-family: 'Proxima Nova', sans-serif;
            margin-left: 7px;
          }
        }
      }
    }
  }
}
@media only screen and (min-width: $min-viewport-width) {
  .preparation-for-manual {
    padding-top: 19px;
    min-height: $min-height-mobile-container;
    max-width: $mobile-wrapper-width;
    position: unset;
  }
}
</style>
